<template>
  <v-form ref="form">
    <p>{{ $t('select_amount') }}</p>
    <p>{{ $t('total_available_points') }} {{ cashOutDetails.totalPoints }}</p>
    
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-btn text v-on:click="togglePoints('decrease')"><p class="title">-</p></v-btn>

        <v-col align="center" justify="center">
          <p class="title pa-0 ma-0">{{ cashOutDetails.points }} {{$t('points')}}</p>
          <p class="pa-0 ma-0">{{$t('equivalent_to')}}</p>
          <p class="pa-0 ma-0">{{ cashOutDetails.currency}} {{ cashOutDetails.amountInCurrency }}</p>
        </v-col>

        <v-btn text v-on:click="togglePoints('increase')"><p class="title">+</p></v-btn>
      </v-row>
    </v-col>
  </v-form>  
</template>

<script>
export default {
  props: {
    cashOutDetails: Object
  },
  methods: {
    togglePoints(operation) {
      if (operation == "increase") {
        if (this.cashOutDetails.points + this.cashOutDetails.multiplierPoints <= this.cashOutDetails.totalPoints && this.cashOutDetails.points < this.cashOutDetails.maxToRedeem) {
          this.cashOutDetails.multiplier++
          this.cashOutDetails.points += this.cashOutDetails.multiplierPoints
        } else {
          this.$store.state.snackBarText = this.$t('exceeded_points')
          this.$store.state.showSnackBar = true
        }
      } else if (operation == "decrease") {
        if (this.cashOutDetails.points <= this.cashOutDetails.minToRedeem) {
          this.$store.state.snackBarText = this.$t('min_points_not_met')
          this.$store.state.showSnackBar = true
        } else {
          this.cashOutDetails.multiplier--
          this.cashOutDetails.points -= this.cashOutDetails.multiplierPoints
        }
      }
    },
  }
}
</script>